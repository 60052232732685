<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader pl-5">
          <div class="headerOfPage">Job Orders</div>
        </div>
      </v-col>

      <v-col
        md="6"
        cols="12"
        v-if="
          user.parent_id == 4 && user.children_id == null && projectCountry == 1
        "
      >
        <div class="conOfHeader text-right pr-5">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="createNewJobOrder()">
              <v-icon>mdi-plus</v-icon>
              Create New Job Orders
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col
        md="6"
        cols="12"
        v-if="
          (projectCountry == 2 && createdByUser.id == user.id) ||
          assignTo == user.id
        "
      >
        <div class="conOfHeader d-flex justify-end text-right pr-5">
          <div class="btnAdd">
            <!-- {{ createdByUser.id }} -->
            <v-btn
              class="btnAddPrimary"
              @click="createNewJobOrder()"
              :disabled="po_number == null"
            >
              <v-icon>mdi-plus</v-icon>
              Create New Job Orders
            </v-btn>
          </div>
          <div class="btnAdd mx-2">
            <!-- {{ createdByUser.id }} -->
            <v-btn
              class="btnAddPrimary"
              @click="dialogProjectPo = true"
              v-if="po_number == null"
            >
              <v-icon>mdi-plus</v-icon>
              Add Po
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex mb-5 justify-space-between">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <label class="eachLabel">Status</label>
            <v-select
              outlined
              v-model="search.status"
              :items="statusList"
              item-text="name"
              item-value="id"
              placeholder="Status"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="confBtns d-flex">
              <v-btn
                class="srearchBnt btnAddPrimaryOutline mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                SEARCH
              </v-btn>
              <v-btn class="clearBtn btnCancelOutline mr-4" @click="onClear">
                CLEAR
              </v-btn>
              <!--  <v-btn class="btnPrimary mr-4" @click="sendReminder()">
                Reminder
              </v-btn> -->
            </div>
          </v-col>
        </v-row>
      </form>

      <form
        v-on:submit.prevent="onSearchTxt()"
        class="conFormSubmit conSearchForm mt-5"
      >
        <v-row>
          <v-col cols="12">
            <label class="eachLabel">Search</label>
            <v-text-field
              outlined
              v-model="searchTxt"
              type="text"
              class="form-control"
              id="inputSearch"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="7"
              hide-default-footer
            >
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  :to="{
                    name: 'jobOrdersProcess',
                    params: { id: item.id },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="jobOrderPreview(item)">
                      <v-list-item-title class="statusTxt">
                        Preview
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-dialog scrollable v-model="dialogProject" max-width="800" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Add New Job Order</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="!showSupplierField">
                <div class="conFormSubmit">
                  <label class="eachLabel">Supplieres Name</label>
                  <v-select
                    class="JobOrderSelectOption"
                    placeholder="Select Supplier Name"
                    :items="suppliers"
                    outlined
                    solo
                    required
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    attach
                    v-model="formItem.supplier_name"
                    :error-messages="supplierErrors"
                    @input="$v.formItem.supplier_name.$touch()"
                    @blur="$v.formItem.supplier_name.$touch()"
                    autocomplete="off"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12" v-if="showSupplierField">
                <div class="conFormSubmit">
                  <label class="eachLabel">Supplieres Name</label>
                  <v-text-field
                    class="JobOrderSelectOption"
                    placeholder="Type Supplier Name"
                    :items="suppliers"
                    outlined
                    solo
                    required
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    attach
                    v-model="formItem.supplier_name"
                    :error-messages="supplierErrors"
                    @input="$v.formItem.supplier_name.$touch()"
                    @blur="$v.formItem.supplier_name.$touch()"
                    autocomplete="off"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12"
                ><a @click="showSupplierField = !showSupplierField"
                  >other Supplieres</a
                ></v-col
              >

              <v-col md="6" cols="12">
                <div class="conFormSubmitJobOrder">
                  <v-select
                    class="JobOrderSelectOption"
                    outlined
                    v-model="requestID"
                    :items="requestList"
                    item-text="name"
                    item-value="id"
                    placeholder="Status"
                    hide-details="true"
                    :menu-props="{ bottom: true, offsetY: true }"
                    attach
                    append-icon="mdi-chevron-down"
                    @change="changeRequestID()"
                  ></v-select>
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  listRequestsWithSalesApprove &&
                  listRequestsWithSalesApprove.length > 0
                "
              >
                <div class="conFormSubmitJobOrder">
                  <div class="conOfListRequests">
                    <section
                      class="eachRequestList"
                      v-for="(item, i) in listRequestsWithSalesApprove"
                      :key="`item-${i}`"
                    >
                      <v-row class="align-center">
                        <v-col md="7" cols="12">
                          <div class="conFormSubmit">
                            <div class="conCheckBox d-flex align-center">
                              <v-checkbox
                                :value="item.id"
                                :key="`item-${i}`"
                                v-model="
                                  listRequestsWithSalesApprove[i].isChecked
                                "
                                @change="
                                  changeRequestApprove(i, item.isChecked)
                                "
                              >
                                <template v-slot:label>
                                  <div class="labelCheckList">
                                    <span>Request ID:</span>
                                  </div>
                                </template>
                              </v-checkbox>
                              <div class="labelCheckList">
                                <router-link
                                  :to="{
                                    name: 'request-process',
                                    params: { id: item.id },
                                  }"
                                  target="_blank"
                                >
                                  {{ item.item_name }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col md="5" cols="12">
                          <div class="conCostInput">
                            <div class="d-flex align-center">
                              <label class="eachLabelCost"
                                >Final Cost:
                                <small
                                  >(Qty:
                                  {{ item.cost.quantity_request }})</small
                                ></label
                              >
                              <v-text-field
                                class="eachInputCost"
                                placeholder="Final Cost"
                                outlined
                                solo
                                hide-details="auto"
                                v-model="
                                  listRequestsWithSalesApprove[i].cost.cost_name
                                "
                                :disabled="item.id != item.isChecked"
                                @keypress="isNumber($event)"
                              ></v-text-field>
                            </div>
                            <div
                              class="reqField"
                              v-show="
                                listRequestsWithSalesApprove[i].requiredItem ==
                                true
                              "
                            >
                              this field is required
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </section>
                    <div class="noRequest reqField" v-if="noChooseJobOrderReq">
                      No Request Selected
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col
                cols="12"
                v-if="
                  listExtrasRequestsWithSalesApprove &&
                  listExtrasRequestsWithSalesApprove.length > 0
                "
              >
                <hr v-if="listRequestsWithSalesApprove.length > 0" />
                <v-col>Extras</v-col>
                <div class="conFormSubmitJobOrder">
                  <div class="conOfListRequests">
                    <section
                      class="eachRequestList"
                      v-for="(item, i) in listExtrasRequestsWithSalesApprove"
                      :key="`item-${i}`"
                    >
                      <v-row class="align-center">
                        <v-col md="7" cols="12">
                          <div class="conFormSubmit">
                            <div class="conCheckBox d-flex align-center">
                              <v-checkbox
                                :value="item.request_id"
                                :key="`item-${i}`"
                                v-model="
                                  listExtrasRequestsWithSalesApprove[i]
                                    .isChecked
                                "
                                @change="
                                  changeExtrasRequestApprove(i, item.isChecked)
                                "
                              >
                                <template v-slot:label>
                                  <div class="labelCheckList">
                                    <span>Request ID:</span>
                                  </div>
                                </template>
                              </v-checkbox>
                              <div class="labelCheckList">
                                <router-link
                                  :to="{
                                    name: 'request-process',
                                    params: { id: item.request_id },
                                  }"
                                  target="_blank"
                                >
                                  {{ item.item_name }}
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col md="5" cols="12">
                          <div class="conCostInput">
                            <div class="d-flex align-center">
                              <label class="eachLabelCost"
                                >Final Cost:
                                <small
                                  >(Qty:
                                  {{ item.cost.quantity_request }})</small
                                ></label
                              >
                              <v-text-field
                                class="eachInputCost"
                                placeholder="Final Cost"
                                outlined
                                solo
                                hide-details="auto"
                                v-model="
                                  listExtrasRequestsWithSalesApprove[i].cost
                                    .cost_name
                                "
                                :disabled="item.request_id != item.isChecked"
                                @keypress="isNumber($event)"
                              ></v-text-field>
                            </div>
                            <div
                              class="reqField"
                              v-show="
                                listExtrasRequestsWithSalesApprove[i]
                                  .requiredItem == true
                              "
                            >
                              this field is required
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </section>
                    <div class="noRequest reqField" v-if="noChooseJobOrderReq">
                      No Extra Requests Selected
                    </div>
                  </div>
                </div>
              </v-col>
              <div
                class="loaderContainerSection smallLoader"
                v-else-if="isLoadingListRequests"
              >
                <v-progress-circular
                  :size="80"
                  :width="4"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <v-col cols="12" class="mt-7" v-else>
                <div class="noRequestsFound">No Extra Requests Found</div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem()"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProjectPo" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD PO</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">PO Number</label>
                <v-text-field
                  class="eachInput"
                  placeholder="PO Number"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItemPO.number"
                  required
                  :disabled="quotationApproved != false"
                  :error-messages="numberErrors"
                  @input="$v.formItemPO.number.$touch()"
                  @blur="$v.formItemPO.number.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel"
                  >Select Quotation Type If you don't have PO</label
                >

                <v-radio-group v-model="quotationApproved" row>
                  <v-radio
                    label="Quotation Approved"
                    color="success"
                    value="Quotation Approved"
                  ></v-radio>
                  <v-radio
                    label="Sample Quotation"
                    color="success"
                    value="Sample Quotation"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogProjectPo">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="savePOItem"
            :disabled="isLoadingSavePO"
            :loading="isLoadingSavePO"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";

import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import store from "@/store";

export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        supplier_name: { required },
      },
      formItemPO: {
        number: { required },
      },
    };
    return validations;
  },
  data: () => ({
    dialogProject: false,
    dialogConform: false,
    isLoadingSave: false,
    isLoadingSuppliers: false,
    suppliers: [],
    isLoadingListRequests: false,
    noChooseJobOrderReq: false,
    isLoadingRequestsWithSalesApprove: false,
    requestID: 1,
    requestList: [
      {
        id: 1,
        name: "Purchasing Type",
      },
      {
        id: 2,
        name: "Printing Type",
      },
      {
        id: 3,
        name: "Production Type",
      },
      {
        id: 4,
        name: "Photography Type",
      },
      {
        id: 5,
        name: "Extras Type",
      },
    ],
    statusList: [
      {
        id: 1,
        name: "Create Job Order",
      },
      {
        id: 2,
        name: "Sales Reject",
      },
      {
        id: 3,
        name: "Sales Approve",
      },
      {
        id: 4,
        name: "Cost Control Hold",
      },
      {
        id: 5,
        name: "Cost Control Approve",
      },
      {
        id: 6,
        name: "Finance  Reject",
      },
      {
        id: 7,
        name: "Finance  Approve Job Order",
      },
      {
        id: 8,
        name: "Finance  Send to CO",
      },
      {
        id: 9,
        name: "CEO Reject",
      },
      {
        id: 10,
        name: "CEO Approve",
      },
    ],
    showSupplierField: false,
    havePo: false,
    quotationApproved: false,
    dialogProjectPo: false,
    sampleQuotation: false,
    isLoading: false,
    isLoadingSearch: false,
    isLoadingSavePO: false,
    isLoadingExtrasRequestsWithSalesApprove: false,
    isLoadingListExtrasRequests: false,
    editedIndex: -1,
    listExtrasRequestsWithSalesApprove: [],
    lisExtrasRequestOrg: null,

    listRequestsWithSalesApprove: [],
    lisRequestOrg: null,
    filteredSalesApprove: null,
    filteredExtrasSalesApprove: null,
    model: [],
    formItemPO: {},
    pagination: {
      current_page: 1,
    },
    formItem: {
      request_ids: null,
      actual_costs: null,
    },
    defaultItem: {
      request_ids: null,
      actual_costs: null,
    },
    searchTxt: "",
    search: {
      status: "",
    },
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "STATUS", value: "status_message" },
      { text: "CREATE DATE", value: "created_at" },
      { text: "", value: "actions", sortable: false },
    ],
    listData: [],
    actionNeeded: null,
  }),
  components: { EmptyState },
  methods: {
    sendReminder() {
      // this.isLoadingSavePO = true;
      this.formItem.job_order_id = String(this.$route.params.id);
      apiServices.post("job-orders/reminder", this.formItem).then((res) => {
        if (res) {
          console.log("Joborder_id", this.$route.params.id);
        } else {
          this.isLoadingSavePO = false;
        }
      });
    },
    savePOItem() {
      if (this.quotationApproved) {
        this.formItemPO.number = this.quotationApproved;
      } else {
        this.$v.$touch();
        if (this.$v.formItemPO.$error) return;
      }

      this.isLoadingSavePO = true;
      this.formItemPO.project_id = String(this.$route.params.id);
      apiServices.post("pos", this.formItemPO).then((res) => {
        if (res) {
          this.isLoadingSavePO = false;
          this.po_number = true;
          store.commit("setpo_number", "true");
          this.getListData(this.$route.params.id, this.pagination.current_page);

          this.closeDialogProjectPo();
        } else {
          this.isLoadingSavePO = false;
        }
      });
    },
    closeDialogProjectPo() {
      this.dialogProjectPo = false;
      this.quotationApproved = false;
      this.editedIndexPO = -1;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItemPO = Object.assign({}, this.defaultItemPO);
      });
    },
    changeRequestApprove(id, isChecked) {
      if (!isChecked) this.listRequestsWithSalesApprove[id].actual_costs = "";
    },
    changeExtrasRequestApprove(id, isChecked) {
      if (!isChecked) {
        this.listExtrasRequestsWithSalesApprove[id].actual_costs = "";
      }
    },
    getProjectdata() {
      apiServices.get(`projects/${this.$route.params.id}`).then((res) => {
        if (res) {
          store.commit("setCreatedByUser", res.data.user);
          store.commit("setProjectCountry", res.data.country_id);
          store.commit("setpo_number", res.data.po_number);
        }
      });
    },
    createNewJobOrder() {
      this.getRequestsSalveApprove(1);
      this.dialogProject = true;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    onSearchTxt() {
      this.pagination.current_page = 1;
      this.pathByOaramSearchTxt();
    },
    onClear() {
      this.search.status = "";
      this.pathByOaramSearch();
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilter) != JSON.stringify(query)) {
        this.$router.replace({
          name: "jobOrdersRequests",
          query: query,
        });
        this.updatedFilter = query;
      }
    },
    pathByOaramSearchTxt() {
      const query = Object.entries(this.searchTxt).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilterTxt) != JSON.stringify(query)) {
        this.$router.replace({
          name: "jobOrdersRequests",
          query: query,
        });
        this.updatedFilterTxt = query;
      }
    },
    jobOrderPreview(item) {
      this.isLoading = true;
      apiServices.get(`job-orders/${item.id}`).then((res) => {
        if (res) {
          window.open(res.data.url, "name").focus();
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    saveItem() {
      // first iteration for get required Elements
      // second iteration for get filtered items has costs
      // third iteration get strings items
      //
      if (this.noChooseJobOrderReq == false) {
        this.$v.formItem.$touch();
        if (this.$v.formItem.$error) return;
      }

      this.filteredSalesApprove = this.listRequestsWithSalesApprove.filter(
        (obj) => {
          return obj.isChecked == obj.id && obj.cost.cost_name;
        }
      );
      this.filteredExtrasSalesApprove =
        this.listExtrasRequestsWithSalesApprove.filter((obj) => {
          return obj.isChecked == obj.request_id && obj.cost.cost_name;
        });

      if (
        Object.keys(this.filteredSalesApprove).length ||
        Object.keys(this.filteredExtrasSalesApprove).length
      ) {
        this.noChooseJobOrderReq = false;
        let request_ids = [];
        let actual_costs = [];
        this.filteredSalesApprove.filter((obj) => {
          request_ids.push(obj.id);
          actual_costs.push(obj.cost.cost_name);
        });
        this.filteredExtrasSalesApprove.filter((obj) => {
          request_ids.push(obj.request_id);
          actual_costs.push(obj.cost.cost_name);
        });
        this.formItem.request_ids = request_ids.join();
        this.formItem.actual_costs = actual_costs.join();
        this.isLoadingSave = true;
      } else {
        this.$v.formItem.$touch();
        this.noChooseJobOrderReq = true;
        return;
      }

      let formItems = {
        ...this.formItem,
        project_id: String(this.$route.params.id),
        type_id: String(this.requestID),
      };

      apiServices.post("job-orders", formItems).then((res) => {
        if (res) {
          this.pagination.current_page = 1;
          this.getListData(this.$route.params.id, this.pagination.current_page);
          this.isLoadingSave = false;
          this.closeProjectModal();
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    closeProjectModal() {
      this.dialogProject = false;
      this.filteredSalesApprove = [];
      this.filteredExtrasSalesApprove = [];
      this.listRequestsWithSalesApprove = this.lisRequestOrg;
      this.listExtrasRequestsWithSalesApprove = this.lisExtrasRequestOrg;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPage(page) {
      this.getListData(this.$route.params.id, page);
      window.scrollTo(0, 0);
    },
    getListData(id, page) {
      this.listData = [];
      this.isLoading = true;
      true,
        apiServices
          .get(
            `job-orders?per_page=7&page=${page}&project_id=${id}&status=${this.search.status}&search=${this.searchTxt}`
          )
          .then((res) => {
            if (res) {
              false, (this.listData = res.data);
              console.log("listData", this.listData);
              this.pagination = res.meta;
              this.isLoading = false;
            } else this.isLoading = false;
          });
    },
    getSuppliersNames(id) {
      this.suppliers = [];
      this.isLoadingSuppliers = true;
      true,
        apiServices.get(`projects/suppliers/${id}`).then((res) => {
          if (res) {
            false, (this.suppliers = res.data);
            this.isLoadingSuppliers = false;
          } else this.isLoadingSuppliers = false;
        });
    },
    getRequestsSalveApprove(id) {
      this.listRequestsWithSalesApprove = [];
      this.isLoadingListRequests = true;
      this.isLoadingRequestsWithSalesApprove = true;
      apiServices
        .get(
          `requests?per_page=500&type_id=${id}&project_id=${this.$route.params.id}&status=6`
        )
        .then((res) => {
          if (res) {
            this.listRequestsWithSalesApprove = res.data;
            this.lisRequestOrg = JSON.parse(JSON.stringify(res.data));
            this.isLoadingRequestsWithSalesApprove = false;
            this.isLoadingListRequests = false;
          } else {
            this.isLoadingRequestsWithSalesApprove = false;
            this.isLoadingListRequests = false;
          }
        });
    },
    getExtrasRequestsSalveApprove(id) {
      this.listExtrasRequestsWithSalesApprove = [];
      this.isLoadingListExtrasRequests = true;
      this.isLoadingExtrasRequestsWithSalesApprove = true;
      apiServices
        .get(
          `request/extras?per_page=500&extra_request_type=${id}&project_id=${this.$route.params.id}&status=6`
        )
        .then((res) => {
          if (res) {
            this.listExtrasRequestsWithSalesApprove = res.data;
            this.lisExtrasRequestOrg = JSON.parse(JSON.stringify(res.data));
            this.isLoadingExtrasRequestsWithSalesApprove = false;
            this.isLoadingListExtrasRequests = false;
          } else {
            this.isLoadingExtrasRequestsWithSalesApprove = false;
            this.isLoadingListExtrasRequests = false;
          }
        });
    },
    changeRequestID() {
      this.getRequestsSalveApprove(this.requestID);
      this.getExtrasRequestsSalveApprove(this.requestID);
    },
  },

  created() {
    this.getListData(this.$route.params.id, this.pagination.current_page);
    this.getSuppliersNames(this.$route.params.id);
    this.getProjectdata(this.$route.params.id);
  },
  watch: {
    dialogProject(val) {
      val || this.closeProjectModal();
    },
    "$route.params.query": {
      handler: function () {
        this.getListData(this.$route.params.id, this.pagination.current_page);
        this.getProjectdata(this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      "createdByUser",
      "user",
      "projectCountry",
      "po_number",
      "assignTo",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Request" : "Edit Request";
    },

    numberErrors() {
      const errors = [];
      if (!this.$v.formItemPO.number.$dirty) return errors;
      !this.$v.formItemPO.number.required &&
        errors.push("PO Number Is Required.");
      return errors;
    },
    supplierErrors() {
      const errors = [];
      if (!this.$v.formItem.supplier_name.$dirty) return errors;
      !this.$v.formItem.supplier_name.required &&
        errors.push("supplier Name Is Required.");
      return errors;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_job-orders.scss";
</style>
